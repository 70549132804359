import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'
import { CartPlus } from '~components/Svg'
import Button from '~components/Button'
import Image from '~components/Image'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import useWindow from '~utils/useWindow'
import { useCart } from '~context/siteContext'

const ProductTile = ({ className, product }) => {

	const [hover, setHover] = useState(false)
	const transformRef = useRef()
	const [transformHeight, setTransformHeight] = useState()
	const w = useWindow()
	const variant = product?.store?.variants[0]
	const { addItem, openCart } = useCart()

	useEffect(() => {
		setTransformHeight(transformRef?.current?.clientHeight)
	}, [transformRef?.current, w.innerWidth])

	const add = variant => {
		addItem({
			...variant, 
			qty: 1,
			productTitle: product?.store?.title,
			productImage: product?.store?.previewImageUrl,
			// Currently we can't limit the qty because Sanity no longer holds the data
			maxQty: null,
			slug: product?.store?.slug
		})
		openCart()
	}

	return (
		<Wrap 
			className={className} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<ThumbnailLink to={resolveLink(product)}>
				<Thumbnail 
					image={product?.thumbnailImage} 
					aspectratio={1} 
					hover={hover} 
					transformHeight={transformHeight}
				/>
			</ThumbnailLink>
			<Content>
				<Title className='h4' to={resolveLink(product)}>{product?.store?.title}</Title>
				<ShortDesk className='h5'>
					{product?.shortDescription}
				</ShortDesk>
				<AddToCart onClick={() => add(variant)}>
						Add to cart
					<CartIcon />
				</AddToCart>
			</Content>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const ThumbnailLink = styled(Link)`
	display: block;
`
const Thumbnail = styled(Image)`
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: var(--l);
	position: relative;
	z-index: 1;
	${mobile}{
		margin-bottom: var(--xl);
	}
`
const Content = styled.div`
	position: relative;
	padding-left: var(--m);
`
const ShortDesk = styled.div`
	margin-bottom: var(--l);
	${mobile}{
		margin-bottom: var(--m);
	}
`
const AddToCart = styled(Button)`
	max-width: 250px;
`
const CartIcon = styled(CartPlus)`
	width: 23px;
	margin-left: 30px;
	margin-top: -3px;
`
const Title = styled(Link)`
	flex: 1;
	margin-bottom: var(--l);
	display: block;
	${mobile}{
		margin-bottom: var(--m);
	}
`


ProductTile.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
}

export default ProductTile