import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import { Link } from 'gatsby'
import { Rarr } from '~components/Svg'
import resolveLink from '~utils/resolveLink'

const RecipeTile = ({ className, recipe }) => {
	const [hover, setHover] = useState(false)

	return (
		<Wrap 
			className={className} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<LinkOverlay to={resolveLink(recipe)}/>
			<Thumbnail image={recipe?.image} aspectRatio={1}/>
			<Title>{recipe?.title}<RightArrow hover={hover}/></Title>
			<Subtitle className='med-ital'>{recipe?.subtitle}</Subtitle>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Thumbnail = styled(Image)`
	margin-bottom: 30px;
	border-radius: 10px;
	overflow: hidden;
	${tablet}{
		margin-bottom: 25px;
	}
`
const Title = styled.h6`
	margin-bottom: 6px;
`
const Subtitle = styled.div`
`
const LinkOverlay = styled(Link)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
`
const RightArrow = styled(Rarr)`
	width: 10px;
	opacity: ${ props => props.hover ? '1' : '0'};
	transition: opacity 0.25s;
	margin-left: 6px;
`

RecipeTile.propTypes = {
	className: PropTypes.string,
	recipe: PropTypes.object,
}

export default RecipeTile